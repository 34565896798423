import Spline from "@splinetool/react-spline";

export default function Laptop() {
    return (
        <Spline
            class=""
            scene="https://prod.spline.design/e8oyNwjFih9u2Kqg/scene.splinecode"
        />
    );
}
