import Spline from "@splinetool/react-spline";

export default function Earth() {
    return (
        <Spline
            class=""
            scene="https://prod.spline.design/djpL1vXaK4YObJHW/scene.splinecode"
        />
    );
}
